import { useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FeatherIcon from 'feather-icons-react';

import { useNavigate } from "react-router-dom";

function QRCodes() {
    const navigate = useNavigate();

    const videoQR = () => {
        navigate('/QRCodeScanner');
    };

    const intermissionQR = () => {
        navigate('/QRCodeIntermission');
    };

    const video2QR = () => {
        navigate('/QRCodeScannerVideoTwo');
    };

    return (
        <div className="qrcode__container">
            <div className="d-flex justify-content-center align-items-center">
                <img src={`/logo192.png`} alt="logo" style={{ height: 200, marginTop: 200 }} className="mb-5" />
            </div>
            <Container className="mt-5 mb-5">
                <Row className="mt-5">
                    <Col>
                        <Button variant="outline-warning" size="lg" onClick={videoQR}>The Beginning <FeatherIcon icon="chevron-right" size="24" /></Button>
                    </Col>
                </Row>
                
                <Row className="mt-4">
                    <Col>
                        <Button variant="outline-dark" size="lg" onClick={intermissionQR}>The Twist <FeatherIcon icon="chevron-right" size="24" /></Button>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Button variant="outline-dark" size="lg" onClick={video2QR}>The Conclusion <FeatherIcon icon="chevron-right" size="24" /></Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default QRCodes;
