import "../App.css";
import { useState, useRef,useEffect } from "react";

function IosScreen() {  

    return (
        <div className="App">
            <header className="App-header">
                <img src={`images/logo.svg`} className="App-logo" alt="logo" />
                <h1>iOS App store</h1>
            </header>

        </div>
    );
}
export default IosScreen;
