import React from "react";

interface StepProps {
  imageSrc: string;
  title: string;
  description: string;
}

const Step: React.FC<StepProps> = ({ imageSrc, title, description }) => (
  <div className="flex flex-col items-center text-center min-w-[240px] w-[327px] max-sm:w-full max-sm:mx-auto">
    <img
      loading="lazy"
      src={imageSrc}
      alt={title}
      className="object-contain self-center max-w-full aspect-square w-[258px]"
    />
    <h3 className="mt-8 text-4xl font-bold text-white">{title}</h3>
    <p className="mt-8 text-3xl text-white max-sm:mb-5">{description}</p>
  </div>
);

const Section4: React.FC = () => {
  const steps: StepProps[] = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/17b9acc3d12a9c7c4b10ba0dee88b8a38edfe9478490f1bed9ff887b3ae92c8a?apiKey=3994eb2c170b47cd83412d68bba28691&",
      title: "Get a Yellow Book",
      description: "Pick up any book enabled with the Yellow App to start your interactive reading journey.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/6c4dd7c1a869b39ab7d8f383d6288d5143a66ba3778cc8a7e7c5fecae4608559?apiKey=3994eb2c170b47cd83412d68bba28691&",
      title: "Download The Yellow App",
      description:
        "Install the app to access exclusive content tied directly to your book.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/784fe566bb287950ce7836e82736e098ee958fe5f600cc2cd89db31bbaac38b9?apiKey=3994eb2c170b47cd83412d68bba28691&",
      title: "Read Book",
      description:
        "Let the story unfold in your hands and connect in a whole new way.",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/4a2ed69fd2199b45de9bb05ebceb93c2be73a4617c3dc2369d3561bffd820f91?apiKey=3994eb2c170b47cd83412d68bba28691&",
      title: "Engage with book",
      description: "Simply scan the QR code on the page to watch scenes, engage with other readers, and more!",
    },
  ];

  return (
    <section className="flex flex-col items-center mt-56 max-w-full w-[1538px] max-md:mt-10 max-sm:px-5 max-sm:mt-24">
      <h1 className="mt-10 text-7xl font-medium leading-none text-center text-white max-md:max-w-full max-md:text-4xl max-sm:mt-2.5 font-cocon">
        How Does It Work
      </h1>
      <h2 className="self-stretch mt-7 text-4xl font-bold text-center text-yellow-400 max-md:max-w-full">
        Easy as 1, 2, 3...
      </h2>
      <div className="flex flex-wrap gap-10 items-center justify-center mt-7 max-md:max-w-full">
        {steps.map((step, index) => (
          <Step key={index} {...step} />
        ))}
      </div>
    </section>
  );
};

export default Section4;
