import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  useParams,
  useLocation,

} from "react-router-dom";
import OpenApp from "react-open-app";
import { FiAlertCircle } from "react-icons/fi";
const DownloadApp = (props) => {

    return (
      <div>
      <img src={`/logo192.png`}  alt="logo" style={{height:80,marginTop:50}} />

    <div style={{marginTop:100,padding:50}}>
      
      <div style={{flex:1}}>
     <strong > App Not Found <FiAlertCircle style={{marginLeft:5}} /></strong >
     <p>It looks like the yellow app is not installed on your device.</p>
     <p>Please download it using the link below.</p> 
      </div>
        <a href="http://139.59.41.92/uploads/apk/TheYellowApp.apk" download>   
        <button style={{borderColor: 'black',borderRadius:12,padding:20}} >
        Download the Yellow App!
        </button>
      </a>
      </div>
      </div>
    )
}

export default DownloadApp;