import React from "react";

interface PopupFormProps {
  onClose: () => void;
}

const PopupForm: React.FC<PopupFormProps> = ({ onClose }) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    alert("Thank you for signing up for early access!");
    onClose(); // Close the popup after submission
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-white rounded-lg p-8 max-w-md w-full text-center relative">
        <h2 className="text-3xl font-bold mb-4 text-neutral-800">Get Early Access</h2>
        <p className="text-lg mb-6 text-neutral-600">
          Enter your email to join our early access list.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email address"
            className="w-full px-4 py-3 mb-4 text-lg border border-gray-300 rounded-[47.5px] focus:outline-none focus:border-yellow-400"
            required
          />
          <button
            type="submit"
            className="w-full px-8 py-3.5 text-2xl font-bold shadow-2xl rounded-[47.5px] bg-customYellow text-neutral-800"
          >
            Get Early Access
          </button>
        </form>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-neutral-800 text-2xl font-bold"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default PopupForm;
