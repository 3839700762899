import React, { useState } from "react";
import PopupForm from "./PopupForm.tsx"; // Adjust the path if necessary

const Section2: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <section className="flex flex-col lg:flex-row gap-10 items-center mt-56 max-w-full text-2xl min-h-[741px] w-full px-5 md:px-10 lg:px-20 max-md:mt-10 max-sm:mt-24">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/3ffcc021881a70904f02b5091ad2b73c4cf2ccc6d84137dd3f4174f4ba2954f5?apiKey=3994eb2c170b47cd83412d68bba28691&"
          alt="Book experience illustration"
          className="object-contain grow shrink self-stretch my-auto rounded-none aspect-[1.1] min-w-[240px] lg:w-1/2 max-md:max-w-full max-sm:order-first lg:order-last"
        />
        <div className="flex flex-col grow shrink self-stretch my-auto min-w-[240px] lg:w-1/2 text-left max-md:text-center">
          <h1 className="pt-2 max-w-full text-7xl font-medium text-white w-full font-cocon lg:text-left max-md:text-center max-md:text-4xl">
            A Better Way to Experience Books
          </h1>
          <p className="mt-6 text-neutral-700 text-3xl lg:text-left max-md:text-center">
            <span className="text-white">
              Imagine the pages of a book unfolding like a movie. The Yellow App transforms reading into a multi-sensory experience, letting you immerse yourself fully in every chapter. Dive into stories that go beyond words—this is where books meet cinema.
            </span>
          </p>
          <button
            onClick={handleButtonClick}
            className="gap-2.5 self-start mt-6 px-8 py-3.5 font-bold shadow-2xl rounded-[47.5px] bg-customYellow text-neutral-800 max-md:px-5 max-sm:self-center"
          >
            Get Early Access
          </button>
        </div>
      </section>

      {isPopupVisible && <PopupForm onClose={handleClosePopup} />}
    </>
  );
};

export default Section2;
