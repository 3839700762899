import React, { useState } from "react";
import PopupForm from "./PopupForm.tsx"; // Adjust the path as needed

const NavigationBar: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <nav className="flex flex-wrap gap-10 items-center self-center w-full text-2xl font-bold min-h-[133px] text-neutral-800 max-md:max-w-full px-5 md:px-10 lg:px-10">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/a7b1f43a77acc2f10a9dfb80e8de04f307bd95da7927fccb972c8156ca23d520?apiKey=3994eb2c170b47cd83412d68bba28691&"
          alt="Yellow App Logo"
          className="object-contain shrink-0 self-stretch my-auto aspect-[1.49] w-[198px] max-sm:mx-auto"
        />
        <button
          onClick={handleButtonClick}
          className="ml-auto gap-2.5 self-stretch px-8 py-3.5 my-auto shadow-2xl min-w-[240px] rounded-[47.5px] bg-customYellow max-md:px-5 max-sm:mx-auto"
        >
          Get Early Access
        </button>
      </nav>

      {isPopupVisible && <PopupForm onClose={handleClosePopup} />}
    </>
  );
};

export default NavigationBar;
