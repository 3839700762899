import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="relative -mt-80 max-sm:mt-0 flex flex-col items-start px-11 pt-10 pb-16 w-full bg-yellow-400 min-h-[04px] max-md:px-5 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/801f8f5981853bf266d32a4aa34fce2c84e4fc1dae1a6f868610d3466f1e9023?apiKey=3994eb2c170b47cd83412d68bba28691&"
        alt="Yellow App Logo"
        className="object-contain max-w-full rounded-none aspect-[1.68] w-[260px] max-sm:mx-auto"
      />
      <div className="mt-36 w-full text-2xl font-bold text-center text-neutral-800 max-md:mt-10 max-md:max-w-full">
        The Yellow App LLC |{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=6ff7ecb7-7af1-4292-9a62-b5424a317db3"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-neutral-800"
        >
          Terms and Conditions
        </a>{" "}
        |{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7e5b5bf2-c604-4638-954f-fc5887e5665b"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-neutral-800"
        >
          Privacy Policy
        </a>{" "}
        |{" "}
        <a
          href="https://app.termly.io/policy-viewer/policy.html?policyUUID=85e9cfe5-899f-4c70-b0da-671607d8db63"
          target="_blank"
          rel="noopener noreferrer"
          className="underline text-neutral-800"
        >
          Cookie Policy
        </a>
      </div>
    </footer>
  );
};

export default Footer;
