import "../App.css";
import { useState, useRef,useEffect } from "react";
import NavigationBar from "./components/NavigationBar.tsx";
import ContentSection from "./components/ContentSection.tsx";
import Footer from "./components/Footer.tsx";

function HomeScreen() {

    return (
        <div className="App">
            <div className="flex overflow-hidden flex-col pt-7 bg-neutral-800">
      <NavigationBar />
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/7fb44e47c4c546598882165c540ccdd3c093518748190bc27ebbd310d3eca382?apiKey=3994eb2c170b47cd83412d68bba28691&"
        alt=""
        className="object-contain z-10 self-end mt-28 mr-32 aspect-[1.01] w-[94px] max-md:mt-10 max-md:mr-2.5"
      />
      <ContentSection />
      <Footer />
    </div>

        </div>
    );
}
export default HomeScreen;
