
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QRCodes from "./screen/QRCodes";
import QRCodeScanner from "./screen/QRCodeScanner";
import QRCodeIntermission from "./screen/QRCodeIntermission";
import VerifyQR from "./screen/VerifyQR";
import DownloadApp from "./screen/DownloadApp";
import HomeScreen from "./screen/HomeScreen";
import IosScreen from "./screen/IosScreen";
import AndroidScreen from "./screen/AndroidScreen";
import QRCodeScannerVideoTwo from "./screen/QRCodeScannerVideoTwo"

function App() {
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/qrcodes" element={<QRCodes />} />
          <Route path="/iosstore" element={<IosScreen />} />
          <Route path="/androidstore" element={<AndroidScreen />} />
          <Route path="/verifyQR" element={<VerifyQR />} />
          <Route path="/downloadApp" element={<DownloadApp />} />
          <Route path="/qrCodeScanner" element={<QRCodeScanner />} />
          <Route path="/QRCodeIntermission" element={<QRCodeIntermission />} />
          <Route path="/QRCodeScannerVideoTwo" element={<QRCodeScannerVideoTwo />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
