import React, { useState } from "react";
import PopupForm from "./PopupForm.tsx"; // Make sure to adjust the path if necessary

const Section1: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <section className="flex flex-col lg:flex-row gap-10 items-center max-w-full text-white w-full px-5 md:px-10 lg:px-20">
        <img
          loading="lazy"
          src="images/section1image.png"
          alt="Book illustration"
          className="object-contain self-stretch my-auto rounded-none aspect-[0.95] min-w-[240px] lg:w-1/2 max-w-full max-sm:self-center"
        />
        <div className="flex flex-col self-stretch my-auto min-w-[240px] lg:w-1/2 text-left max-sm:text-center max-sm:items-center">
          <h1 className="text-7xl font-medium leading-[101px] lg:max-w-full md:text-7xl md:leading-[89px] font-cocon max-sm:text-4xl">
            What Is Your Favorite <span className="text-white">Book</span> to
            Watch?
          </h1>
          <p className="text-3xl md:max-w-full">
            Discover a whole new way to experience your favorite stories. The Yellow App bridges the gap between reading, watching, and commenting, making every page come to life.
          </p>
          <button
            onClick={handleButtonClick}
            className="gap-2.5 self-start mt-6 px-8 py-3.5 text-2xl font-bold shadow-2xl min-w-[240px] rounded-[47.5px] bg-customYellow text-neutral-800 md:px-5 max-sm:self-center"
          >
            Get Early Access
          </button>
        </div>
      </section>

      {isPopupVisible && <PopupForm onClose={handleClosePopup} />}
    </>
  );
};

export default Section1;
