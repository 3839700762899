import React, { useState } from "react";

const Section3: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError(""); // Clear error message on input change
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    // Simulate form submission
    setIsSubmitted(true);
    setEmail(""); // Clear the email input field after submission

    // Replace the following with actual submission logic if needed
    setTimeout(() => {
      alert("Thank you for signing up for early access!");
      setIsSubmitted(false);
    }, 1000);
  };

  return (
    <section className="flex flex-col mt-56 max-w-full w-[1568px] max-md:mt-10 max-sm:px-5 max-sm:mt-24">
      <h2 className="text-4xl font-bold text-center text-white max-md:max-w-full max-sm:text-3xl">
        Be The First To Know
      </h2>
      <h1 className="mt-10 text-7xl font-medium leading-none text-center text-white max-md:max-w-full max-md:text-4xl max-sm:mt-2.5 font-cocon">
        Get Early Access
      </h1>
      <p className="self-center mt-10 text-3xl text-center text-white max-md:max-w-full">
        Join our community and be among the first to experience an entirely new way to enjoy books.
        <br />
        Early access means exclusive perks and special content tailored for you.&nbsp;
      </p>
      <form onSubmit={handleSubmit} className="flex flex-wrap gap-2.5 justify-center items-center self-center mt-10 min-h-[120px] max-md:max-w-full">
        <label htmlFor="emailInput" className="sr-only">
          Enter your email address
        </label>
        <input
          id="emailInput"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email address"
          className="gap-2.5 self-stretch px-8 py-2.5 my-auto text-3xl rounded-3xl border border-solid bg-neutral-100 border-zinc-300 min-h-[57px] min-w-[240px] shadow-[0px_100px_80px_rgba(0,0,0,0.07)] text-neutral-400 w-[844px] max-md:px-5 max-md:max-w-full"
          required
        />
        <button
          type="submit"
          className="gap-2.5 self-stretch px-8 py-3.5 my-auto text-2xl font-bold shadow-2xl min-w-[240px] rounded-[47.5px] bg-customYellow text-neutral-800 max-md:px-5"
          disabled={isSubmitted} // Disable button after submission
        >
          {isSubmitted ? "Submitting..." : "Get Early Access"}
        </button>
      </form>
      {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
    </section>
  );
};

export default Section3;
