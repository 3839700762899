/**
 * This code was generated by Builder.io
 */
import React from "react";
import Section1 from "./Section1.tsx";
import Section2 from "./Section2.tsx";
import Section3 from "./Section3.tsx";
import Section4 from "./Section4.tsx";
import Section5 from "./Section5.tsx";

const ContentSection: React.FC = () => {
  return (
    <main className="flex z-10 flex-col items-center mt-0 w-full max-md:max-w-full max-sm:flex max-sm:flex-col max-sm:mt-5">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </main>
  );
};

export default ContentSection;
