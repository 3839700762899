import React from "react";

const Section5: React.FC = () => {
  return (
    <section className="flex flex-col-reverse lg:flex-row gap-10 items-start mt-56 max-md:mt-10 max-md:max-w-full px-5 md:px-10 lg:px-20 max-sm:mt-24">
      <div className="flex flex-col min-w-[240px] lg:w-1/2 w-[751px] max-md:max-w-full">
        <h2 className="text-7xl font-medium leading-none text-white font-cocon text-left max-md:max-w-full max-md:text-4xl">
          What They Say?
        </h2>
        <h3 className="mt-8 text-4xl font-bold text-yellow-400 text-left max-md:max-w-full max-sm:mx-auto">
          Our Reviews
        </h3>
        <div className="flex flex-wrap gap-6 items-start mt-8 w-full text-white max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/9d5963d85509e0ce0a9fde16f16ba3aafd6d8ec3bb6139171da47ee4cb2e1401?apiKey=3994eb2c170b47cd83412d68bba28691&"
            alt="Jennie's profile"
            className="object-contain shrink-0 rounded-full aspect-square w-[175px] max-sm:mx-auto"
          />
          <div className="flex flex-col grow shrink min-w-[240px] w-[518px] text-left max-md:max-w-full">
            <h4 className="text-5xl font-medium leading-none max-md:max-w-full max-md:text-4xl max-sm:mx-auto">
              Jennie
            </h4>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/678386817b20a2bbbaeab3c5e2cd53d2b1e85000f2809245ad0fb18c3379b73f?apiKey=3994eb2c170b47cd83412d68bba28691&"
              alt="Rating stars"
              className="object-contain mt-5 max-w-full aspect-[4.88] w-[234px]"
            />
            <blockquote className="mt-5 text-4xl max-md:max-w-full">
              "Going from reading to watching and back to reading was like ice
              cream to my brain"
            </blockquote>
          </div>
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/3994eb2c170b47cd83412d68bba28691/583b3d7851f3baf5c337bd19074bdf1caedfee83e877195e440838d36b1ce162?apiKey=3994eb2c170b47cd83412d68bba28691&"
        alt="Yellow App device mockup"
        className="object-contain aspect-[0.5] min-w-[240px] lg:w-1/2 w-[662px] max-md:max-w-full max-md:order-first lg:order-last"
      />
    </section>
  );
};

export default Section5;
